@import url("https://fonts.googleapis.com/css?family=Avenir:400,600,700");

* {
  box-sizing: border-box;
}

/* .ant-modal-content {
  background-color: transparent;
  box-shadow: 0px 0px 0px 0px !important;
} */

/* Ant Design Checkbox button styling */

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: white;
  background: green;
  border-color: #1c811c;
}

.ant-radio-button.ant-radio-button-wrapper-checked:hover {
  color: white;
  background: green;
  border-color: #1c811c;
}
.ant-radio-checked {
  color: white;
  background: green;
  border-color: #1c811c;
}

.ant-radio-button-wrapper:hover {
  color: white;
  background: green;
  border-color: #1c811c;
}

.ant-radio-button-wrapper:hover {
  color: white;
  background: green;
  border-color: #1c811c;
}

.ant-radio-button-wrapper:active {
  color: white;
  background: green;
  border-color: #1c811c;
}

.ant-modal-close-x {
  position: absolute;
  margin-top: 24px;
  font-size: 30px;
  background-color: orangered;
  color: white;
}

body {
  margin: 0;
  font-size: 0.9rem;
  overflow-x: hidden;
  color: #393939;
  background-color: #fff;
  font-family: "Avenir", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
header,
section {
  /* margin: 0; */
}

button,
input {
  border: none;
  outline: none;
}

section {
  width: 100%;
  margin: 0;
}
.align-end {
  align-items: end;
}

.float-right{
  float: right;
}

.rounded {
  background-color: white;
  border: 1px solid black;
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 25px;
}

.page-6-card {
  background-color: #fff;
}

.page-6-card:hover {
  background-color: lightgreen;
  cursor: pointer;
}

.rounded:hover {
  background-color: #d5ffd5;
  cursor: pointer;
}

.p-r {
  position: relative !important;
}

.no-padding {
  padding: 0 !important;
}
.p-5 {
  padding: 5px !important;
}
.p-10 {
  padding: 10px !important;
}
.p-15 {
  padding: 15px !important;
}
.p-20 {
  padding: 20px !important;
}
.p-40 {
  padding: 40px !important;
}
.p-t-40 {
  padding-top: 40px !important;
}
.p-r-5 {
  padding-right: 5px !important;
}
.p-l-5 {
  padding-left: 5px !important;
}

.no-margin {
  margin: 0 !important;
}
.m-20 {
  margin: 20px 20px 20px 20px !important;
}
.m-30 {
  margin: 30px 30px 30px 30px !important;
}
.m-l-5 {
  margin-left: 5px !important;
}
.m-l-10 {
  margin-left: 10px !important;
}
.m-l-20 {
  margin-left: 20px !important;
}
.m-r-5 {
  margin-right: 5px !important;
}
.m-r-10 {
  margin-right: 10px !important;
}
.m-r-20 {
  margin-right: 20px !important;
}
.m-r-30 {
  margin-right: 30px !important;
}
.m-r-40 {
  margin-right: 40px !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-t--20 {
  margin-top: -20px !important;
}
.m-t-40 {
  margin-top: 40px !important;
}
.m-t-5 {
  margin-top: 5px !important;
}
.m-b-5 {
  margin-bottom: 5px !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-b-40 {
  margin-bottom: 40px !important;
}
.m-b-50 {
  margin-bottom: 50px !important;
}
.m-input-5 {
  margin: 15px 15px 5px 15px;
}

.no-border {
  border: none !important;
}
.no-wrap {
  flex-wrap: nowrap !important;
}
.family {
  font-family: "system-ui";
}
.wrap {
  flex-wrap: wrap !important;
}
.flex {
  display: flex !important;
}
.flex-direction-column {
  flex-direction: column;
}
.flex-h-center {
  justify-content: center !important;
}

.css-1s2u09g-control {
  min-height: 47px !important;
}

.flex-v-center {
  align-items: center !important;
}
.self-align-end {
  align-self: flex-end;
}
.pointer {
  cursor: pointer !important;
}
.underline {
  text-decoration: underline !important;
}
.center-text {
  text-align: center !important;
}
.right-text {
  text-align: right !important;
}
.space-between {
  justify-content: space-between !important;
}
.float-right {
  float: right !important;
}
.border-check {
  border: 1px solid red;
}
.inline-block {
  display: inline-block !important;
}
.bold-text {
  font-weight: bold !important;
}

.max-w-400 {
  max-width: 400px !important;
}

.form-group input,
.form-group textarea,
.form-group select {
  color: black !important;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-half,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  display: inline-block;
  position: relative;
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
}
.col-1 {
  width: 8.33%;
}
.col-2 {
  width: 16.66%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33%;
}
.col-5 {
  width: 41.65%;
}
.col-6,
.col-half {
  width: 50%;
}
.col-7 {
  width: 58.33%;
}
.col-8 {
  width: 66.66%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33%;
}
.col-11 {
  width: 91.63%;
}
.col-12,
.w-100 {
  width: 100% !important;
  position: relative;
}

.w-100 {
  width: 100% !important;
}
.w-90 {
  width: 90% !important;
}
.w-80 {
  width: 80% !important;
}

.w-60 {
  width: 60% !important;
}
.w-65 {
  width: 65% !important;
}

.w-75 {
  width: 75% !important;
}

.w-85 {
  width: 85% !important;
}

.w-50 {
  width: 50% !important;
}

.w-40 {
  width: 40% !important;
}
.w-30 {
  width: 30% !important;
}
.w-35 {
  width: 35% !important;
}

.w-20 {
  width: 20% !important;
}
.w-10 {
  width: 10% !important;
}

.h-100 {
  height: 100% !important;
}
.primary-color {
  background-color: #d5ffd5;
}

.white-color {
  background-color: white !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 20px);
  position: relative;
  margin-right: -10px;
  margin-left: -10px;
}

.content {
  /* max-width: 1300px; */
  margin: 0 auto;
  padding: 20px;
  flex-wrap: wrap;
  display: flex;
}

.bg-white {
  background-color: #ffffff !important;
}
.bg-gray {
  background-color: #dddddd !important;
}
.bg-light-gray {
  background-color: #f5f7f9 !important;
}

.widget {
  position: fixed;
  right: 15px;
  bottom: 0;
  /* width:100px; */
  z-index: 1000;
}
/* .widget input[type='text']:hover, widget input[type='text']:focus, widget input[type='text']:active{
border-bottom:2px solid #25d366 !important;
padding:5px
} */

.widget a {
  text-decoration: none;
  color: black;
}
.widget-transition {
  transition: all 0.5s ease-out;
  background-color: rgba(37, 211, 102, 1);
  min-width: 200px;

  /* background-color: rgba(37, 211, 102, 0.6); */
}

.widget-transition:hover {
  transition: all 0.5s ease-out;
  background-color: rgba(37, 211, 102, 1);
  min-width: 200px;
}
.widget-transition2 {
  transition: all 0.5s ease-out;
  background-color: rgba(37, 211, 102, 1);
}
.messageUs:hover {
  color: rgba(37, 211, 102, 1);
}
.messageUs {
  cursor: pointer;
  margin-top: 15px;
}

.bold-text {
  font-weight: bold !important;
}

.bold {
  font-weight: bold !important;
  font-family: sans-serif !important;
}

footer {
  position: relative;
  background-color: #454545;
  color: #ddd;
  height: 58px;
}

div.banner {
  width: 100%;
  padding: 130px 0 100px;
  background-size: cover;
  background-blend-mode: luminosity;
  /* background-image: url("../images/Abuja.png"); */
  background-color: #1c811c55;
  background-position-y: 20%;
}

div.banner .login {
  margin: 0 auto;
  max-width: 600px;
  padding: 40px;
  position: unset;
  border-radius: 10px;
  background-color: #fffffff0;
}
div.login {
  display: flex;
}
div.login .coat-of-arm {
  border-right: 1px solid #c6c6c6;
  padding-top: 15px;
  padding-right: 40px;
  width: 270px;
}
div.login .coat-of-arm img {
  width: 140px;
  height: auto;
}
div.login .coat-of-arm h1 {
  font-size: 1.58rem;
  font-family: sans-serif;
  line-height: 1.5rem;
  font-weight: 700;
}
div.login .coat-of-arm h4 {
  font-size: 1.25rem;
  font-weight: 400;
}
div.login .form {
  padding-left: 40px;
  width: 100%;
  text-align: center;
}
.login-form-group {
  width: 100%;
  margin-bottom: 20px;
}
.login-form-group {
  width: 100%;
  margin-bottom: 15px;
}
.login-form-group input {
  width: 100%;
  padding: 13px;
  border: 1px solid #c6c6c6;
  border-radius: 5px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.9rem;
  color: #777;
}
div.login .form button {
  width: 100%;
  color: #fff;
  padding: 13px;
  background-color: #017d34;
  border-radius: 5px;
  outline: none;
  border: none;
  transition: all 0.3s ease-out;
}
div.login .form button:hover {
  background-color: #017d3488;
}
.intro {
  max-width: 800px;
  margin: 30px auto 10px;
}
div.dept-news {
  background-color: #ffffff;
  box-shadow: 5px 3px 22px #958e8e29;
  border: 1px solid #c6c6c6;
  border-radius: 10px;
  padding: 20px;
  position: relative;
}
div.dept-news h4 {
  background-color: #1c811c;
  color: #ffffff;
  padding: 5px 10px 2px;
  display: inline-block;
  position: relative;
  right: 20px;
}
.headline {
  align-items: center;
  border-bottom: 1px solid #c6c6c6;
  padding-bottom: 20px;
}
.headline:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.headline .date {
  background-color: #1c811c1f;
  padding: 5px 10px;
  text-align: center;
}
.headline .date .m {
  font-size: 0.7rem;
}
.headline .date .d {
  font-size: 1.4rem;
}
.headline .title {
  margin-left: 10px;
}
div.bottom {
  border-left: 1px solid #c6c6c6;
  padding: 0 20px;
}
div.bottom h2 {
  color: #1c811c;
  font-family: sans-serif;
  margin-bottom: 20px;
}
div.bottom ol {
  padding-left: 20px;
}
div.bottom ul {
  padding: 0;
  list-style: none;
}

@media only screen and (max-width: 900px) {
  .col-8,
  .col-7,
  .col-6 {
    width: 100%;
    flex: 1 0 100%;
  }
  .col-5,
  .col-4,
  .col-3 {
    width: 50%;
    flex: 1 0 50%;
  }
}

@media only screen and (max-width: 600px) {
  .col-5,
  .col-4,
  .col-3 {
    width: 100%;
    flex: 1 0 100%;
  }
  div.banner {
    padding-top: 60px;
  }
  div.login {
    display: block;
    margin: 20px !important;
  }
  div.login .coat-of-arm {
    border-right: none;
    padding-bottom: 20px;
    padding-right: 0;
    text-align: center;
    width: 100%;
  }
  div.login .form {
    padding-left: 0;
  }
}

/* Notification modal */
.notification_modal {
  position: absolute;
  left: 65%;
  font-weight: normal !important;
  background: white;
  padding: 10px 20px;
  box-shadow: 5px 3px 22px #958e8e29;
  width: 35%;
  max-height: 500px;
  color: #393939;
  /* text-align: center; */
  z-index: 9;
  overflow-y: scroll;
}
.notification_modal > div {
  padding: 10px 0;
  border-bottom: 1px solid #f3f3f3;
}

.notification_modal1 {
  position: fixed;
  /* left: 0%; */
  /* font-weight: normal !important; */
  background: white;
  /* padding: 10px 20px; */
  box-shadow: 5px 3px 22px #958e8e29;
  /* width: 100%;  */
  max-height: 500px;
  color: #393939;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  background-color: #1c811c66;
  /* position: relative; */
  z-index: 1000;
  /* padding: 100px 30%; */
  overflow: auto;
  /* z-index: 1000;  */
}
.notification_hover:hover {
  background-color: rgb(213, 255, 213);
}

.notification_modal1 > div {
  padding: 10px 0;
  border-bottom: 1px solid #f3f3f3;
}
.notification_modal2 {
  position: fixed;
  /* left: 0%; */
  /* font-weight: normal !important; */
  background: transparent;
  /* padding: 10px 20px; */
  box-shadow: 5px 3px 22px #958e8e29;
  /* width: 100%;  */
  /* max-height: 500px;  */
  color: #393939;
  display: block;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0;
  /* padding:50px; */

  background-color: #1c811c66;
  /* position: relative; */
  z-index: 900;
  /* padding: 100px 30%; */
  overflow: auto;
  /* z-index: 1000;  */
}

.notification_footer {
  border-bottom: none !important;
  padding: 15px 0 !important;
  color: #fce9a0 !important;
}

.editable-row {
  cursor: pointer;
}
