.show { display: block!important; }

.badger{
  background-color: red;
  color: white;
  position: absolute;
  top:-15px;
  left: -8px;
  width:15px;
  height: 15px;
  border-radius: 50%;
  /* padding:3px; */
 display: flex;
 align-items: center;
 justify-content: center;
 padding: 10px;
 box-sizing: border-box;
 font-size: 12px;
 /* font-weight: bold; */
}

#react-paginate ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

#react-paginate li {
  display: inline-block;
}

.portal {
  padding-left: 260px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #F5F7F9;
}

.san-sherif {
  font-family: sans-serif!important;
}

.underlined {
  text-decoration: underline!important;
}

/* HEADER STYLING */
header {
  background-color: #1C811C;
  color: #fff;
  width: calc(100% - 260px);
  z-index: 100;
  height: 62px;
  position: fixed;
}
.header-content {
  padding: 10px 20px!important;
}
.search-box {
  width: 260px;
}
.search-box input {
  background-color: #00000000;
  border: none;
  color: #fff;
  padding: 10px;
  width: 100%;
}
.search-box input::placeholder {
  color:#BCBCCB;
}
header .notification svg {
  color: #BCBCCB;
  width: 23px;
  height: auto;
}
header .notification span.indicator {
  width: 9px;
  height: 9px;
  position: absolute;
  background-color: #FFC06A;
  border: 1px solid #fff;
  border-radius: 50%;
  right: 11px;
  top: 2px;
}
.header-right .right-item {
  border-right: 1px solid #C6C6D68D;
  padding: 5px 10px;
}
.header-right .right-item:last-child {
  border-right: none;
  padding: 5px 0 5px 10px;
}
header .nav-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}
header .nav-menu li {
  padding: 0 10px;
  font-size: 0.8rem;
}
.user-avater-details p {
  line-height: 0.9rem;
}
.user-avater-details .name {
  font-size: 0.7rem;
  font-weight: 700;
}
.user-avater-details .role {
  font-size: 0.6rem;
}
.user-avater-details img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.user-avater-details .arrow svg {
  width: 19px;
  height: auto;
  color: #E8A85F;
}
/* END OF HEADER STYLING */

.main-content {
  padding-top: 90px;
  min-height: calc(100vh - 58px);
  height: 100%;
}

/* LOCATION DETAILS */
.location-details p {
  line-height: 0.9rem;
}
.location-details .location {
  font-size: 0.7rem;
  font-weight: 700;
}
.location-details .login-time {
  font-size: 0.55rem;
}
.location-details img {
  width: 21px;
  height: auto;
}
/* END OF LOCATION DETAILS */

/* SEARCH INPUT STYLING */
.search-input {
  border: 1px solid #1C801C;
  padding: 5px 10px;
  background-color: #D5FFD5;
  border-radius: 3px;
  width: 350px;
}
.search-input input {
  border: none;
  background-color: #00000000;
  padding: 5px;
  width: 100%;
}
/* END OF SEARCH INPUT STYLING */

/* TABLE STYLING */
.table-view {
  display: table;
  width: 100%;
  border-spacing: 0 10px;
}
.table-view .table-header {
  display: table-header-group;
  background-color: #D5FFD5;
  font-weight: bold;
}
.table-view .table-body {
  display: table-row-group;
}
.table-view .table-row {
  display: table-row;
}
.table-view .table-body .table-row {
  background-color: #fff;
  border-top: 10px solid transparent;
}
.table-view .table-body .table-row:hover {
  background-color: #D5FFD5;
}
.table-view .table-body .table-row.active {
  background-color: #D5FFD5;
}
.table-view .table-body .table-row .dropdown-close-btn {
  position: absolute;
  left: 20px;
  font-size: 2.5rem;
  background-color: yellow;
  top: 10px;
  box-shadow: 0 0 5px;
}
.table-view .table-header .th,
.table-view .table-body .table-row .td {
  display: table-cell;
  padding: 10px;
  vertical-align: middle;
  text-align: left;
}
.table-view .table-row .th:last-child,
.table-view .table-row .td:last-child {
  text-align: right;
  float: right;
}
.table-row .td.colspan {
  position: absolute;
  left: 0;
  width: 100%;
}
.table-row .td.empty {
  border-color: transparent;
}

button.add-button {
  border: 1px solid #1C811C;
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 5px 10px;
}
button.add-button svg {
  font-size: 1.5rem;
  color: #1C811C;
}

.actions button.action-btn {
  background-color: #1C811C45;
  border: none;
  padding: 8px 8px 4px;
}
.actions button.del {
  background-color: #FF121B45;
}
.actions button.action-btn svg {
  color:#1C811C;
  width: 20px;
  height: auto;
}
button.options-btn {
  background-color: #1C811C;
  color: #fff;
  padding: 8px 10px;
  width: 145px;
  border-radius: 3px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
button.options-btn svg {
  color: #fff;
}
.options-menu {
  border: 1px solid #c6c6c6;
  background: #ffffff;
  padding: 0 5px;
  position: absolute;
  min-width: 100%;
  box-shadow: 0px 3px 6px #00000029;
  text-align: left;
  z-index: 10;
}
.options-menu .menu-item {
  padding: 10px;
  border-bottom: 1px solid #c6c6c6;
  cursor: pointer;
}
.options-menu .menu-item:hover {
  background-color: #eee;
}
.options-menu .menu-item:last-child {
  border-bottom: none;
}

button.booking-btn {
  background-color: yellow;
  color: #1C811C;
  padding: 10px;
  width: 120px;
  border-radius: 50px;
}
/* END OF TABLE STYLING */

/* OVERLAY */
.message-overlay,
.loading-overlay,
.overlay {
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color:#1C811C66;
  position: fixed;
  z-index: 1000;
  padding: 100px 20px;
  overflow: auto;
}

.message-overlay,
.loading-overlay,
.overlay1 {
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color:transparent;
  position: fixed;
  z-index: 1000;
  padding: 100px 20px;
  overflow: auto;
}


.message-overlay {
  background-color: #fff;
}
svg.close-btn {
  top: 15px;
  right: 30px;
  position: fixed;
  font-size: 5rem;
  color: #fff;
  background-color: #1C811CCC;
  border: 1px solid;
  box-shadow: 0 0 2px black;
  z-index: 1;
  transition: all 0.3s ease-out;
}
svg.close-btn:hover {
  background-color: #c4303566;
}
.loading-overlay {
  z-index: 1001;
}
.loading-inner-box,
.modal-box {
  margin: 0 auto;
  position: unset;
  background-color: #fff;
}
.modal-box .header {
  padding: 10px 12px;
  border-bottom: 1px solid #c6c6c6;
}
.loading-inner-box {
  padding: 50px 20px;
  width: 320px;
  text-align: center;
  box-shadow: 0 0 10px #888;
}
/* END OF OVERLAY STYLING */

/* FORM INPUTS STYLING */
.form-group {
  position: relative;
}
.form-group label {
  font-size: .75rem;
  color: #999;
}
.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  height: 39px;
  padding: 10px 10px 10px 0;
  border: none;
  border-bottom: 1px solid #C6C6C6;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: .9rem;
  color: #777;
}

.widget .form-group input,
.widget .form-group textarea,
.widget .form-group select {
  width: 100%;
  height: 39px;
  padding: 10px 10px 10px 0;
  border: none;
  border-bottom: 2px solid #25d366;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: .9rem;
  color: #777;
  background-color:white;

}

.widget .form-group input:hover,
.widget .form-group textarea:hover,
.widget .form-group select:hover {
  width: 100%;
  height: 39px;
  padding: 10px 10px 10px 0;
  border: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: .9rem;
  color: #777;
  background-color:white;
  box-shadow: 0px 2px 9px -9px #25d366;
  border-bottom: 3px solid #25d366;
  border-top: none;
  border-right: none;
  border-left: none;


}
.form-group select::-ms-expand {
	display: none;
}
.form-group textarea {
  height: auto;
  line-height: 1.4rem;
}
.form-group select:disabled,
.form-group input:disabled {
  color: rgb(84, 84, 84);
  cursor: default;
  background-color: rgb(235, 235, 228);
}
.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  outline: none;
  border-width: 2px;
}
.form-group-search input {
  padding-right: 20px;
}
.form-group-search svg {
  position: absolute;
  right: 0;
  bottom: 13px;
}

.select-colored select {
  padding: 10px;
  border: none;
  background-color: #1C811C;
  color: #fff;
  border-radius: 3px;
  outline: none;
}
/* END OF FORM INPUTS STYLING */

/* BUTTON STYLES */
.btn {
  background-color:#1C811C;
  padding: 8px 15px;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.btn:hover {
  background-color:#1C811C88;
}
i.btn {
  font-style: normal;
}
.btn-light {
  background-color:#1C811C1C;
  border: 1px solid#1C811C;
  padding: 7px 15px;
  color: #555;
}
.btn-large {
  padding: 14px!important;
}
.btn-large.btn-light {
  padding: 13px 14px;
}
.btn-round {
  border-radius: 50px!important;
}
.btn-gray {
  background-color: #838383;
  color: #E8E8E8;
}
.btn-small {
  padding: 5px;
  margin: 2px 0;
}
.btn svg {
  font-size: 1rem;
  color:#fff;
}
.btn svg.right {
  margin-left: 10px;
}
.btn svg.left {
  margin-right: 10px;
}
.btn-group {
  border-radius: 10px;
  display: flex;
}
.btn-group button {
  border-radius: 0;
}
.btn-group button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.btn-group button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.btn-del {
  background-color: #DE2222;
}
.btn-del:hover {
  background-color: #DE2222AA;
}
button:disabled,
button:disabled:hover {
  cursor: default!important;
  background-color: #999!important;
}
button:focus {
  outline: none;
}
.btn-loading {
  width: 20px;
  height: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 20px;
}
/* END 0F BUTTON STYLES */

/* FORM WITH TABS STYLING */
.staffid-form-overlay {
  background-color: #1C811C88;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.staffid-form-overlay .inner-box {
  background-color: #fff;
  max-width: 400px;
  transform: translateY(-50%);
  top: 30%;
  position: relative;
}
.tab-btns {
  border-bottom: 1px solid #E9E9F0;
}
.tab-btns button {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid#E9E9F0;
  border-bottom: none;
  background-color: #F8F8F8;
  color: #43425D;
  padding: 10px 13px;
  margin-right: 5px;
}
.tab-btns button:last-child {
  margin-right: 0;
}
.tab-btns button.active {
  background-color: #fff;
}
.user-full-details .left {
  width: calc(100% - 250px);
}
.user-full-details .left .content {
  background-color: #fff;
  box-shadow: 0px 2px 6px #0000000A;
}
.user-full-details div.right {
  width: 250px;
  padding: 20px 10px;
}
.profile-brief img {
  width: 170px;
  height: 170px;
  border-radius: 50%;
}
.profile-brief .brief {
  background-color: #fff;
  box-shadow: 0px 2px 6px #0000000A;
  padding: 20px 10px;
  font-family: sans-serif;
}
.profile-brief .brief hr {
  width: calc(100% + 20px);
  margin: 10px -10px;
}
.profile-brief .brief p {
  font-size: 0.8rem;
}
/* END OF FORM WITH TABS STYLING */

/* REQUEST MANAGER FORM STYLING */
.create-request-btns {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.create-request-btns button {
  width: 200px;
  padding: 20px;
  background-color: #fff;
  text-align: center;
}
.create-request-btns button svg {
  font-size: 5rem;
  display: block;
  margin: 0 auto;
}
.create-request-btns button.close-btn {
  width: 80px;
  height: 80px;
  padding: 0;
}
.request-form {
  background-color: #fff;
}
.request-form::before {
  background-color: green;
  content: '';
  width: 100%;
  height: 5px;
  position: absolute;
  top: 0;
  left: 0;
}
.meeting-modal {
  justify-content: center;
  max-width: 1240px;
  margin: 0 auto;
}
.meeting-booker-calendar {
  min-width: 250px;
}
.meeting-booker .header,
.meeting-booker-calendar .header {
  padding: 10px;
  border-bottom: 1px solid #c6c6c6;
}
.meeting-booker .header button {
  padding: 7px 10px;
  background-color: #F74F11;
  color: #fff;
  border-radius: 50px;
}

.present-value .label {
  color: #777;
  font-size: 0.8rem;
  margin-bottom: 3px;
}
.present-value .value {
  padding-left: 25px;
}

.calendar-meeting-list .item {
  padding: 10px 12px;
  border-bottom: 5px solid #dddddd;
}
.calendar-meeting-list .item .time {
  width: 70px;
}
.calendar-meeting-list .item .title {
  width: calc(100% - 70px);
}

.document-preview .item .footer {
  padding: 5px;
  background-color: #D5FFD5;
}
.document-preview .item .footer b {
  font-size: x-small;
}
.document-preview .item .footer div.doc-title {
  width: calc(100% - 60px);
}
.document-preview .item .footer .actions {
  width: 60px;
}
.document-preview .item .footer .actions button {
  padding: 5px 6px 3px;;
  background-color: #1C811C45;
  color: #1C811C;
}
.document-preview .item .footer .actions button.del {
  background-color: #FF121B45;
}

.calendar {
  margin: 0 auto;
  width: 360px;
}
/* END OF REQUEST MANAGER FORM STYLING */
